.player-config-wrapper{
    height: calc(100% - 3.5rem);
}

.selcted-player-name-wrapper{
    border-bottom: 1px solid;
    .selected-player-header{
        font-weight: bold;
    }

    .player-name-span{
        font-weight: bold;
        color: #36c036;
    }
}

.team-filter-wrapper{
    display: flex;
    margin-left: 2rem;
    align-items: center;
}

.filter-container-style{
    display: flex;
}

.search-box-style{
    width: 30%;
}

.position-filter{
    width: 300px;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
}

.team-checkbox2-style{
    margin-left: 1rem;
}

.sticky-action-button-wrapper{
    position: absolute;
    bottom: 5px;
    right: 0.5rem;
}