@import "../../../node_modules/bootstrap/scss/functions";
@import "../../common/styles/variables";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.table-responsive {
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 100;

    &,
    & th {
      background-color: #fff;
    }
  }
  tbody {
    & td {
      overflow-wrap: anywhere;
    }
  }

  button:disabled {
    svg {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.react-table-wrapper {
  .btn {
    text-transform: capitalize !important;
  }

  .pagination {
    margin-top: 0.5rem !important;
  }

  .btn-link {
    text-decoration: none;
    text-transform: capitalize;
    padding: 0 !important;
  }
}

.test-list,
.venues-list {
  .table-responsive {
    th,
    td {
      &:nth-child(1) {
        min-width: 16.25rem;
      }
    }
  }
}

.organising-team-list {
  .table-responsive {
    th,
    td {
      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(6) {
        max-width: 20rem !important;
        min-width: 20rem;
      }

      &:nth-child(2) {
        max-width: 12rem !important;
        min-width: 12rem;
      }
    }
  }
}

.scouts-list,
.scouts-list-card {
  .table-responsive {
    th,
    td {
      &:nth-child(2) {
        min-width: 16rem;
        max-width: 16rem !important;
      }

      &:nth-child(6) {
        min-width: 8rem;
      }
    }
  }
}

.athletes-list {
  th,
  td {
    &:nth-child(2) {
      min-width: 16rem;
      max-width: 16rem !important;
    }

    &:nth-child(3) {
      min-width: 8rem;
      max-width: 8rem !important;
    }

    &:nth-child(12) {
      min-width: 18rem;
      max-width: 18rem !important;
    }

    &:nth-child(13) {
      min-width: 15rem;
      max-width: 15rem !important;
    }
  }
}

.institute-list {
  .table-responsive {
    th,
    td {
      &:nth-child(1) {
        min-width: 18rem;
        max-width: 18rem !important;
      }
    }
  }
}

.athlete-insight-list {
  .table-responsive {
    overflow-x: hidden;

    td {
      &:nth-child(1) {
        min-width: 19.5rem;
        max-width: 19.5rem !important;
      }
    }
  }
}

.table-scroll {
  overflow: hidden;
  height: 100%;
  display: flex;

  .card-body {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
  }

  .react-table-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .p-2 {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0px !important;
  }
}

.mapped-venues-list,
.allocate-more-venues-list {
  .react-table {
    th,
    td {
      &:nth-child(2),
      &:nth-child(4) {
        min-width: 16rem;
        max-width: 16rem !important;
      }

      &:nth-child(3) {
        min-width: 12rem;
        max-width: 12rem !important;
      }
    }
  }
}

.athlete-shortlisting {
  .react-table {
    th,
    td {
      &:nth-child(1) {
        min-width: 16rem;
        max-width: 16rem !important;
      }

      &:nth-child(5),
      &:nth-child(9) {
        min-width: 10rem;
        max-width: 10rem !important;
      }
    }
  }
}

.shortlisted-athlete {
  .react-table {
    th,
    td {
      &:nth-child(3),
      &:nth-child(12) {
        min-width: 18rem;
        max-width: 18rem !important;
      }

      &:nth-child(8) {
        min-width: 8rem;
      }
    }
  }
}

.venue-allocation-events-rounds {
  .react-table {
    th,
    td {
      &:nth-child(1) {
        min-width: 16rem;
        max-width: 16rem !important;
      }

      &:nth-child(6) {
        min-width: 12rem;
      }

      &:nth-child(7) {
        min-width: 10rem;
      }
    }
  }
}

.venue-allocation-unAllocated-athletes {
  .react-table {
    th,
    td {
      &:nth-child(3) {
        min-width: 16rem;
        max-width: 16rem !important;
      }
    }
  }
}

.venue-allocation-allocated-athletes {
  .react-table {
    th,
    td {
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 16rem;
        max-width: 16rem !important;
      }
    }
  }
}

.map-athletes-to-timeslots {
  .react-table {
    th,
    td {
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 16rem;
        max-width: 16rem !important;
      }
    }
  }
}

.venue-manager-athlete-list {
  .react-table {
    th,
    td {
      &:nth-child(6) {
        min-width: 16rem;
        max-width: 16rem !important;
      }
    }
  }
}
