@import '../../../node_modules/bootstrap/scss/functions';
@import '../../common/styles/variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.app-content-wrapper {
    flex-grow: 1;
    display: flex;
    @include media-breakpoint-up(lg) {
        height: calc(100% - 7.8125rem);
    }
    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
}

.app-content {
    flex-grow: 1;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up(lg) {
        padding-block: 1rem;

        display: flex;
        flex-direction: column;
        height: 100%;
    }
    @include media-breakpoint-down(lg) {
        padding-top: 1rem;
    }
}

.app-support-text {
    display: flex;
    padding-inline: 1.5rem;
    margin-top: 1.5rem;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    svg {
        flex-shrink: 0;
        margin-right: 0.25rem;
    }
}

.app-content-right {
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
        margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
}
