@import '../../../node_modules/bootstrap/scss/functions';
@import '../../common/styles/variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.app-header {
    padding-block: 0.7rem;
    border-bottom-color: #f2f2f2 !important;
    background-color: $white;

    @include media-breakpoint-up(lg) {
        padding-inline: .75rem;
    }
}

.user-avatar {
    width: 2.125rem;
    height: 2.125rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    background-color: $green;
}

.user-dropdown-button .dropdown-toggle {
    text-decoration: none;
}

.hamburger {
    > span {
        width: 20px;
        height: 2px;
        background-color: $gray-800;
        margin-bottom: 0.25rem;
        display: block;
    }
}
.mobile-menu {
    max-width: 75% !important;
    .offcanvas-header {
        justify-content: flex-end;
        border-bottom: 0;
    }
}
.profile-icon {
    width: 2.125rem;
    height: 2.125rem;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    margin-right: 0.75rem;
}
.profile-name {
    color: $gray-900;
    font-size: 0.875rem;
}
.profile-role {
    color: $gray-700;
    font-size: 0.825rem;
}
.profile-dropdown {
    .dropdown-toggle {
        &::after {
            margin-left: 1rem;
            align-self: flex-start;
            margin-top: 0.5rem;
            border-top: 0.375rem solid;
            border-right: 0.375rem solid transparent;
            border-left: 0.375rem solid transparent;
        }
    }
    .dropdown-menu {
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
        background: $white;
        min-width: 12rem;
        border-radius: 0;
        border: 0;
        left: 100% !important;
        top: 2.75rem !important;
        transform: translateX(-12rem) !important;
    }
    .dropdown-item {
        padding: 0.5rem 1rem 0.6rem 1rem;
        border-bottom: 1px solid $gray-400;
        &:active a {
            color: $white;
        }
        a {
            font-size: 0.875rem;
            font-weight: 400;
            color: $gray-800;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

.futy-logo-style {
    width: 6rem;
    height: 3.5rem;
    background-color: #b3ef00;
    border-radius: 50%;
}

.clear-cache{
    margin-right: 1rem;
}