.add-new-reward-wrapper{
    .add-reward-button{
        margin-left: 20%;
    }

    .width-20-rem{
        width: 20rem;
    }

    .selected-option-logo{
        width: 25px;
        height: 25px;
        margin-left: 5px;
    }

    .option-list-logo{
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}