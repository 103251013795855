.team-color-config-view-wrapper{
    .team-logo-wrapper{
        display: flex;
        flex-direction: column;
    }
    .logo-upload-button{
        align-self: flex-start;
        padding-left: 1.5rem;
        text-decoration: underline;
    }
    .team-logo-style{
        width: 100px;
        height: 100px;
        border: 1px solid gray;
    }

    .team-color-save-button{
        margin-left: 20%;
        margin-top: 2.5rem;
    }

    .star-style{
        color: red; 
        font-size: 18px;
    }

    .input-box-styling .form-control{
        height: 76px;
    }
}
.color-span-wrapper{
    display: flex;
    width: 20%;
}

.span-gap{
    margin-right: 1rem;
}

.width-25{
    width: 20%;
}

.form-control-color {
    width: 5rem;
    height: calc(3.5em + 1.125rem + calc(var(--border-width)* 2));
}
