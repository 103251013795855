.user-reward-small-select-filter{
    div {
        border-radius: 0.5rem;
      }
      .css-13cymwt-control {
        min-height: 44px !important;
      }
      .css-t3ipsp-control {
        min-height: 44px !important;
      }
}

.user-reward-table{
    height: calc(100% - 3.5rem) !important;
    .table-responsive {
        th,
        td {
          &:nth-child(7) {
            min-width: 16.25rem;
          }
        }
      }

      .error-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 16rem;
      }
}

.reward-tab-wrapper{
  .daterange-filter-wrapper{
    margin-top: -1.5rem;
    margin-bottom: 0.5rem;
  }
}