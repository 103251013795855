.date-range-wrapper {
    .static-filter-button:first-of-type{
        margin-left: 0;
    }
    .static-filter-button { 
        margin: 0 0.5rem;
    }
    .form-control:focus{
        border: 1px solid rgba(158, 154, 154, 0.938);
    }
}
