@import "../../../node_modules/bootstrap/scss/functions";
@import '../../common/styles/variables';
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.react-table {
  &.table > :not(caption) > * > * {
    padding: 0.625rem 1rem;
    max-width: 12.5rem;
    border-top-width: $border-width;
    border-right: 1px solid #f2f2f2;
  }

  thead {
    border-color: $gray-400;
    text-transform: uppercase;
  }

  td {
    border-bottom: 1px solid $gray-100;
    font-size: 0.875rem;
  }
  tbody > tr > td {
    color: $gray-900 !important;
  }
  thead > tr > th {
    padding-block: 0.625rem !important;
    font-size: 0.875rem !important;
  }
  .arrow-icon {
    margin: -0.2rem;
    cursor: pointer;
  }
  &.table > thead > tr > *:last-child,
  &.table > tbody > tr > *:last-child {
    border-right-width: 0;
  }
}

.react-table-search {
  .form-control {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaUlEQVR4nO2Wz0pCQRjFf2i6ydop9ArZO1T7FhrlK0TSH+spxNcw61GCaJMFJZn71rqolfHBufBtwjtzLxLkgYELM+ecud98cxhY4Q+hDLSAW+ANmGnY90BztiZXHAIfwHzBGAPNPAwLQM8JPwEXwDawrlEHLoGhW9cVNxo9CX0BJwvEbO5UaxPz6PLOJbQbwNtz5o1Q07I7U/vTULTFfQdKIcSWO9OYsyoCz9I4CiHeiXROPDrSuAkhjUSy7o1FXRp2z1NjKlIlg/GGNKbLNt6MMR7lUOod19mpMRDJEikW19Lox1ynYYbr9CKN49AAGYtoMRiKM3EnwFooueki02IwLfaBb3EPiETXmbdVwt9Q1J8mpp9ANda44MznisGOwqGiYd175c40MU3W18iAhq7FoofAROWtuqzObF5S4Fv2vioYZtpQX93rG6mWp3koqu5lYpvd4r+Y11zZH5ZpnJg/AvfLNl4Bww/dcoIlpDH7/gAAAABJRU5ErkJggg==");
    border: 1px solid #f2f2f2;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.625rem 0.875rem 0.625rem 2.25rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left 0.625rem center;
    background-color: #f8fafe;
    border-radius: 0.625rem;
    line-height: 1.7;
    width: 19.25rem;
  }
  .filter-btn {
    font-size: 0.813rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.react-table-loader {
  min-height: 21.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


.filter-count {
  font-size: 10px;
  z-index: 999;
  margin-bottom: 9px;
  color: #ffffff;
  background: rgb(51, 153, 51);
  width: 13px;
  margin-left: -4px;
  border-radius: 7px;
}
