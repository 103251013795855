.daily-login-wrapper {
  height: calc(100% - 3.5rem);
  .days-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 2rem);
  }
}

.button-height {
  max-height: 38px;
}

.reward-config {
  position: relative;

  .reward-label {
    position: absolute;
    font-size: 12px;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
  }

  .reward-remove-btn {
    all: unset;
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.buttons-wrapper {
  padding-top: 32px;
  position: relative;
  right: 20px;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}

.new-day-label {
  position: absolute;
  font-size: 12px;
  color: red;
  top: 2px;
}
