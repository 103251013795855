.miniLeague-team-datePicker-wrapper{
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .mini-league-team-filter {
        width: 50%;
        padding-right: 1rem;
    }
    .react-datepicker-wrapper {
        width: 50%;
        padding-left: 1rem;
        input {
            max-height: 36px;
            border-color: #cccccc;
        }
    }
}