.input-div{
    width: 25%;
}
.prize-config-table-wrapper{
    th{
        width: 25%;
    }    
}
.add-prize-card{
    border-radius: 0;
    .add-prize-card-body{
        padding: 0 1.5rem;

        .add-prize-button{
            width: 100%;
        }
    }
}
.input-div{
    .border-left-style{
        border-left: 0;
    }
}