.league-season-filter-wrapper {
    display: flex;

    .leagues-select-filter-wrapper{
        width: 50%;
        padding-right: 1rem;
    }

    .seasons-select-filter-wrapper{
        width: 50%;
        padding-left: 1rem;
    }
}