.modal-confirmation {
    .modal {
        &-header {
            padding-left: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .modal-body {
        padding-bottom: 3.75rem;
        .value {
            margin-block: 1rem;
        }
        .title,
        .value {
            font-size: 1.125rem !important;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
            word-break: break-word;
        }
        .info-text {
            font-size: 0.875rem;
            line-height: 1.428;
            margin-block: 1.6875rem;
        }
    }
}

.margin-right-1{
    margin-right: 1rem;
}