.user-document-wrapper{
    height: 100%;

    .card-wrapper{
        height: calc(100% - 2rem);
        overflow: auto;
        .card-body{
            display: flex;
            .text-section{
                width: 30%;
            }
            .image-section{
                flex: 1;
                margin-left: 2rem;
                .no-docs-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
                .doc-view-container-wrapper{
                    display: flex;
                    flex-direction: column;
                    #header-bar{
                        display: none;
                    }
                    .border-styling{
                        border: 1px solid lightgray;
                    }
                    .doc-header-wrapper{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1rem;
                        .left-arrow-button, .right-arrow-button{
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            padding: 5px;
                            margin: 0.5rem
                        }
                    }
                    .doc-view-container{
                        width: 100%;
                        #doc-nav-info{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .go-back-wrapper{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    
    .reject-select-wrapper{
        margin-bottom: 1.5rem;
    }
    .reject-radio-type{
        margin-left: 1rem;
    }
}