.config-info-read-content{
    display: flex;
    justify-content: space-between;

    .option-style{
        display: flex !important;
        align-items: center;
    }
    
    .team1-color-wrapper{
        width: 10rem;
    }
    
    .team2-color-wrapper{
        width: 10rem;
    }
}

