.user-reward-header{
    .button-with-tooltip{
        border-radius: 0.375rem;
        background-color: #000080;

        .download-report-button{
            border-right: 1px solid;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .button-with-tooltip.disabled{
        opacity: 0.65;
    }
}