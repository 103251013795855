  .tab-wrapper.match-tab-wrapper {
    height: calc(100% - 11.5rem);
    .tab-content {
      height: calc(100% - 3.5rem);
    }
}
.header-row-wrapper {
  display: flex;
  .header-filter-wrapper {
    display: flex;
    width: 50%;
    padding-right: 0.8rem;

    .leagues-select-wrapper {
      margin-left: 30px;
      flex: 1;
    }
  }
  .seasons-select-wrapper {
    width: 50%;
    padding-left: 0.8rem;
  }
}

.filter-by-match-id {
  input {
    border-color: #ccc;
    border-right: 0;
    font-size: 1rem !important;
    height: 38px;
    font-size: 1rem !important;
    padding: 2px 10px !important;
    color: #695e5e !important;
    &:focus {
      border-color: #ccc;
    }
  }
  input::placeholder {
    color: black;
  }
  input::placeholder {
    color: #695e5e !important;
    font-size: 0.95rem !important;
  }
  button {
    display: flex;
    align-items: center;
    border-color: #ccc;
    border-left: 0;
    background: none;
    background-color: #fff;
    height: 38px;
    &:disabled {
      border-color: #ccc;
      background-color: #fff;
    }
  }
}

.prize-disbursed-flag-container {
  margin-top: 3.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-with-tooltip {
  border-radius: 0.375rem;
  background-color: #000080;
  display: flex;
  align-items: center;
}

.button-with-tooltip.disabled {
  opacity: 0.65;
}

.special-match-row > td {
  background-color: #B3EF00;
}

.mark-btn, .unmark-btn{
  min-width: 80px;
}
