.teams-color-config-header-row-wrapper{
    display: flex;
    .header-filter-wrapper{
      display: flex;
      width: 50%;
      padding-right: 0.8rem;
  
      .leagues-select-wrapper{
        margin-left: 30px;
        flex: 1;
      }
    }
    .seasons-select-wrapper{
      width: 50%;
      padding-left: 0.8rem;
    }
  }