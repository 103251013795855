.add-mini-league-info-content {
    display: flex;
    justify-content: space-between;
    .align-item-wrapper{
        align-items: baseline;
        .w-65{
            width: 65%;
        }
    }
    .label-style{
        width: 9rem;
        .required-label{
            color: red;
            font-size: large;
        }
    }

    .mini-league-logo-style{
        margin-right: 1rem;
        margin-top: 0.75rem;
    }

    .copy-button:active {
        transform: scale(0.9); 
        transition: transform 0.1s ease; 
        opacity: 0.5;
      }
}