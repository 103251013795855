.tasks-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 4rem;
}
.tasks-wrapper .milestone {
  height: 50%;
}

.tasks-wrapper .tasks {
  height: 50%;
}
