@import '../../../node_modules/bootstrap/scss/functions';
@import '../../common/styles//variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "react-datepicker/dist/react-datepicker.css";

html,
body,
#root {
    height: 100%;
    width: 100%;

    @include media-breakpoint-down(lg) {
        overflow: hidden;
    }
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.app-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        overflow: hidden;
    }
}

.fs-24 {
    font-size: 1.5rem !important;
}

.fs-12 {
    font-size: 0.75rem !important;
}

.fs-14 {
    font-size: 0.875rem !important;
}

.offcanvas-header {
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    padding-inline: 0;
    margin-inline: 16px;
}

.btn {
    text-transform: uppercase;
}

h1 {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
}

.mb-28 {
    margin-bottom: 1.75rem;
}

.mr-1{
    margin-right: 1rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.h-44 {
    height: 2.75rem;
}

.form-control {
    &::placeholder {
        @include media-breakpoint-up(lg) {
            font-size: 0.8125rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 0.875rem;
        }
    }

    & + .form-text {
        -webkit-font-smoothing: antialiased;
    }
}

.form-check-input {
    border-color: $primary;
}

.form-check-input:disabled {
    border-color: gray;
}

.react-table {
    .btn-link {
        text-decoration: underline;
    }
}

.helper-text-style{
    font-size: 0.625rem;
    margin-top: 0.25rem;
}

.table-with-checkbox {
    & .react-table tbody > tr > td,
    & .react-table thead > tr > th {
        &:first-child {
            padding-inline: 1.25rem !important;
            max-width: 2.5rem;

            > div {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

.w-15 {
    width: 15% !important;
}

.react-table.table > :not(caption) > * > * {
    max-width: unset !important;
}

.react-table.table th {
    & > div {
        display: block !important;
        white-space: nowrap;

        & > * {
            display: inline-flex !important;
        }
    }
}

.form-select {
    & div[class$='ValueContainer'] {
        font-size: 0.875rem;
    }

    & div[class$='option'] {
        font-size: 0.875rem;
    }
}

.border-gray {
    border: 1px solid $gray-500;
}

.quill {
    display: flex;
    flex-direction: column;
}

.ql-toolbar {
    flex-shrink: 0;
}

.ql-container {
    min-height: 250px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $white;
}

.ql-editor {
    flex-grow: 1;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.fs-10 {
    font-size: 0.625rem;
}

.fs-20 {
    font-size: 1.25rem;
}

.font-smoothing {
    -webkit-font-smoothing: antialiased;
}

.form-select-async {
    .border-secondary {
        min-height: 2.75rem !important;
        border-color: $gray-500 !important;
    }

    .border-danger {
        min-height: 2.75rem !important;
    }

    .css-1jqq78o-placeholder {
        font-size: 0.8125rem;
        color: $gray-500;
    }
}

.w-fit-content {
    width: fit-content;
}

.h-fit-content {
    height: fit-content;
}

.w-45 {
    width: 45%;
}
.fw-700 {
    font-weight: 700;
}
.text-truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.fs-13 {
    font-size: 0.813rem;
}

.card-header-style{
    font-size: 1.3rem;
    font-weight: 600;
}

.tooltip-icon{
    background: white;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: .875rem;
    color: #000;
    cursor: pointer;
    margin-left: 0.35rem;
    margin-right: 0.35rem;
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-wrapper {
    display: flex;
    flex-direction: column;
    .tab-content {
      .tab-pane {
        height: 100%;
      }
    }
    .nav-tabs {
      border-bottom: 0;
      .nav-item {
        margin-bottom: 0;
        margin-right: 0.5rem;
      }

      .nav-link:active {
        border: 0;
        border-bottom: 2px solid navy !important;
        color: navy !important;
      }
      .nav-link {
        border: 0;
        color: gray;
      }
      .nav-link.active {
        border-bottom: 2px solid navy;
        color: navy;
        font-weight: bold;
      }
    }
  }

  .no-wrap {
    white-space: nowrap;
  }