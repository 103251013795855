.tab-wrapper.config-mini-league-tabs{
    height: calc(100% - 12rem);
    .tab-content{
        height: calc(100% - 5.5rem);
        #addMiniLeaguesTab-tabpane-3, #addMiniLeaguesTab-tabpane-4{
            height: calc(100% - 3.5rem);
        }
    }
}
.add-mini-league-header{
    display: flex ;
}