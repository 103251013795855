.tab-wrapper.reward-tab-wrapper {
    height: calc(100% - 3.5rem);
    .tab-content {
        height: calc(100% - 3.5rem);
        .tab-pane {
            height: 100%;
        }
        .tab-pane#rewardTab-tabpane-2 {
            height: calc(100% - 4.5rem);
        }
    }
}