.small-select-filter {
    div {
        border-radius: 0.5rem;
    }
    .css-13cymwt-control {
        min-height: 38px !important;
    }
    .css-t3ipsp-control {
        min-height: 38px !important;
    }
}