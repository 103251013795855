.search-input {
  padding-bottom: 5px;
  input {
    border-color: #ccc;
    border-right: 0;
    &:focus {
      border-color: #ccc;
    }
  }
  button {
    border-color: #ccc;
    border-left: 0;
    background: none;
    background-color: #fff;
    &:disabled {
      border-color: #ccc;
      background-color: #fff;
    }
  }
}

.select-filter {
  z-index: 200 !important;
}

.select-filter {
  div {
    border-radius: 0.5rem;
  }
  .css-13cymwt-control {
    min-height: 48px !important;
  }
  .css-t3ipsp-control {
    min-height: 48px !important;
  }
}
