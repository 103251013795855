.login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
    background-color: #f8fafe;
}

.bg-white {
    --bg-opacity: 1;
    background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important;
}

.wrapper-style{
    height: 60%;
    border: 1px solid;
    border-radius: 4px;
    width: 30%;
}

.login-header{
    text-align: center;
    margin-bottom: 2rem;
}

.margin-bottom-3{
    margin-bottom: 3rem;
}

.margin-top-3{
    margin-top: 3rem;
}

.login-wrapper{
    text-align: center;
}