@import '../../../node_modules/bootstrap/scss/functions';
@import '../../common/styles/variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.sidebar,
.registration-sidebar {
    @include media-breakpoint-up(lg) {
        background-color: $blue;
        padding: 2.5rem;
        padding-left: 4rem;
        color: $white;

        flex-shrink: 0;
        width: 17.875rem;
    }
    @include media-breakpoint-down(lg) {
        padding: 1.5rem;
        width: 100%;
    }
}
.application-sidebar {
    width: 5rem;
    padding-inline: 0;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
.sidebar {
    .nav-item {
        width: 100%;
        margin-bottom: 2rem;
        &.administration {
            &.active,
            &:hover {
                .menu-icon {
                    svg circle {
                        stroke: $primary;
                    }
                }
            }
        }
    }
    .nav-item > a {
        text-align: center;
        width: 100%;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0.375rem;
            background-color: $orange;
            z-index: 1;
            height: 100%;
            display: none;
        }
        cursor: pointer;
        &:hover,
        &.active {
            &::before {
                display: block;
            }
            background-color: #e5e5f2;
            .menu-name {
                color: $primary;
            }

            & path,
            & rect {
                stroke: $primary;
            }
        }
    }

    .nav-item.home > a {
        &:hover {
            & path,
            & rect {
                stroke: $primary;
            }
        }
    }
}

.collapsable-sidebar {
    width: 16rem;
    padding: 2.5rem 0;
    position: relative;

    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
        margin-right 0.35s ease-in-out, right 0.35s ease-in-out;

    @include media-breakpoint-down(lg) {
        display: none;
    }
    .nav-item {
        &:first-child {
            padding-top: 0;
        }
        margin-bottom: 2rem;
        a {
            text-align: left;
            padding-inline: 1.25rem;
            padding-block: 0;
            min-height: 2.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:hover .menu-name {
                color: $primary;
            }
        }
    }
    .sub-menu {
        display: flex;
        flex-direction: column;
        position: relative;
        &.active {
            background-color: #e5e5f2;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0.375rem;
                background-color: $orange;
                z-index: 1;
                height: 100%;
            }
            .menu-name {
                color: $primary;
            }
            & path,
            & rect {
                stroke: $primary;
            }
        }
    }
    .submenu-arrow {
        margin-left: 0.5rem;
    }
    .submenu-list {
        list-style: none;
        padding-left: 2.25rem;
        margin-bottom: 0.2rem;
        margin-top: 0.625rem;
        a {
            color: $primary;
            font-size: 0.875rem;
            line-height: 1.3;
            font-weight: 400;
            margin-bottom: 0.5rem;
            min-height: auto;
            padding-inline: 1.5rem;
            position: relative;
        }
    }
    .events,
    .time-slot,
    .athlete-shortlisting {
        .menu-icon {
            margin-left: -0.625rem;
        }
        .menu-name {
            margin-left: 0.625rem;
        }
    }
}
.collapsable-sidebar.open {
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
        margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    margin-left: -14.5rem;
}
.menu-name {
    margin-left: 1rem;
    color: $gray-400;
    font-size: 1rem;
}
span.toggle-button {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: $white;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
        rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    position: absolute;
    right: -0.75rem;
    top: 0.5rem;
    cursor: pointer;
}
.open .toggle-button {
    transform: rotate(3.142rad);
}
