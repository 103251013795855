.user-manage-wrapper{
    height: 100%;
    .card-wrapper{
        overflow: auto;
        height: calc(100% - 3rem);
        .manage-user-body{
            .player-profile-image{
                border: 1px solid rgb(197, 193, 193);
                width: 150px;
                height: 150px;
                display: flex;
            }
            .user-profile {
                width: 200px;
                height: 200px;
            }
        
            .user-label{
                width: 7rem;
            }
        }

        .confirm-user-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid;
        }
    
        .button-with-tooltip{
            border-radius: 0.375rem;
            background-color: #000080;
            width: 11rem;
        }
    
        .button-with-tooltip.disabled{
            opacity: 0.65;
        }
    }
}
