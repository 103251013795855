$white: #fff;

$gray-100: #f2f2f2;
$gray-200: #f4efef;
$gray-300: #f7eeec;
$gray-400: #c5cbd4;
$gray-500: #a1a8b5;
$gray-600: #7c818c;
$gray-700: #5b6069;
$gray-800: #363b44;
$gray-900: #272a31;

$blue: #000080;
$blue-secondary: #6666b3;
$orange: #ff9833;
$green: #008000;

$variable-prefix: '';

$body-bg: #f8fafe;
$body-color: $gray-800;

$font-family-sans-serif: 'Montserrat', sans-serif;
$link-decoration: none;

$form-feedback-font-size: 0.625rem;

$input-padding-y: 0.5625rem;
$input-padding-y-lg: 0.5rem;
$input-color: $gray-900;
$input-border-radius: 0;
$input-border-color: $gray-500;
$input-placeholder-color: $gray-500;
$input-btn-padding-x: 1rem;
$input-btn-focus-box-shadow: none;
$input-focus-border-color: $gray-800;
$input-bg: $white;
$form-label-color: $gray-700;
$form-text-color: $gray-600;

$btn-font-size: 0.8125rem;
$btn-line-height: 1.384;
$btn-padding-y: 0.75rem;
$btn-font-weight: 700;

$accordion-bg: $white;

$form-check-input-border-radius: 0;
$form-check-input-focus-box-shadow: none;

$card-bg: $white;
$table-bg: $white;
$table-color: $gray-600;

$card-border-color: $gray-100;
$card-border-radius: 0.625rem;

$badge-padding-x: 0.125rem;

$carousel-indicator-width: 0.5rem !default;
$carousel-indicator-height: 0.5rem !default;
$carousel-indicator-active-bg: #000080;
