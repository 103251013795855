.react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__view-calendar-icon {
    .calender_icon {
      cursor: pointer;
    }
    .calender-icon-wrapper{
      display: flex;
      position: absolute;
      right: 20px;
      align-items: center;
    }

    .margin-style{
      right: 45px;
    }

    .clear-icon{
      margin-right: 1rem;
      margin-top: 2px;
      cursor: pointer;
      border: 1px solid;
      display: flex;
    }
  }
  
  .react-datepicker__view-calendar-icon input {
    padding: 0.5rem 0.625rem !important;
    min-height: 2.75rem;
  }
  
  .date-of-birth {
    .invalid-feedback {
      color: var(--form-invalid-color) !important;
      display: block !important;
    }
    .is-invalid {
      border-color: var(--form-invalid-color) !important;
      background-image: none !important;
    }
  }
  
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    color: $gray-900;
  }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      color: $gray-900;
    }
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0 !important;
    box-sizing: content-box;
    top: 0.8rem;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    left: -1.5rem !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: $primary !important;
    color: white !important;
  }

  .react-datepicker-popper {
    z-index: 101!important;
  }
  

  .react-datepicker__month-dropdown-container--scroll, 
  .react-datepicker__year-dropdown-container--scroll
  {
    border: 1px solid;
    padding: 0 1.5rem;
  }
