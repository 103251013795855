@import "../../../node_modules/bootstrap/scss/functions";
@import "../../common/styles//variables";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.pagination {
  margin-top: 2rem;
  font-size: 0.875rem;
  .pagination-dropdown {
    height: 2rem;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    color: $primary;
    font-weight: 500;
    padding-block: 0 !important;
  }
  .pagination-groupBtn {
    .btn {
      min-width: 2rem;
      max-width: 2rem;
      height: 2rem;
      padding: 0;
    }
  }
}
.goTo-pagenumber {
  .form-control {
    width: 5rem;
    height: 2rem;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    color: $primary;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}
